import React from "react";

export function HomePage() {
    return (
        <>
            <div className="w-full bg-spro-second py-7 font-trebuchet-ms">
                <div className="container w-11/12 xl:w-[1200] mx-auto">
                    <div className="w-full text-2xl md:text-5xl lg:text-6xl lg:mt-8 xl:text-[5.5rem] text-white flex justify-center items-center flex-wrap text-justify">
                        Your scones bakery in Berlin
                    </div>
                    <div className="w-full flex flex-col lg:flex-row justify-center items-center mb-6 lg:mb-12 my-12 px-2 text-xl lg:text-2xl">
                        <div className="w-full flex justify-center items-center">
                            <img src="/img/pin.png" className="h-[70px]"/>
                            <a href="https://www.google.com/maps/place/SPRO+Scones+Bakery+%26+Coffee/@52.5426373,13.4127143,17z/data=!3m1!4b1!4m5!3m4!1s0x47a853121b5ab1ed:0xd6175b3b74774466!8m2!3d52.5426609!4d13.4126367"
                               className="font-light text-white underline underline-offset-4 decoration-dotted hover:no-underline"
                               target="_blank" rel="nofollow">Schönhauser Allee 50A, <br/>10437 Berlin, Germany</a>
                        </div>
                        <div className="w-full mt-6 lg:mt-0 flex justify-center items-center">
                            <img src="/img/clock.png" className="h-[70px]"/>
                            <div className="font-light text-white">Mon – Sat : 9.00–17.00<br/>Sunday:
                                10.00–17.00
                            </div>
                        </div>
                        <div className="w-full mt-6 lg:mt-0 flex justify-center items-center">
                            <img src="/img/chat.png" className="h-[70px]"/>
                            <div className="font-light text-white flex flex-col">
                                <a href="tel:+49 30 44328602" className="underline underline-offset-4 decoration-dotted hover:no-underline">+49 30 44328602</a>
                                <a href="mailto:hello@spro-coffee.com" className="underline underline-offset-4 decoration-dotted hover:no-underline">hello@spro-coffee.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white py-5 font-trebuchet-ms">
                <div className="container w-11/12 xl:w-[1200] mx-auto">
                    <div className="bg-white text-2xl lg:text-4xl text-spro-second text-center">
                        Follow us on instagram &nbsp;<a href="https://www.instagram.com/spro_coffee/" rel="nofollow"
                                                        className="italic underline underline-offset-4 decoration-dotted hover:no-underline"
                                                        target="_blank">@spro_coffee</a>&nbsp; to check out the latest news
                    </div>
                </div>
            </div>
            <div className="w-full bg-white">
                <img src="/img/bg.jpg" alt=""/>
            </div>
            <div className="w-full bg-white py-12 lg:pb-24 font-trebuchet-ms">
                <div className="container w-11/12 xl:w-[1200] mx-auto">
                    <div className="block pb-10 lg:pb-16 text-6xl lg:text-8xl text-spro-second uppercase text-center">Scones</div>
                    <div className="flex flex-col lg:flex-row justify-center text-xl lg:text-3xl">
                        <div className="w-full lg:w-1/2 lg:pr-6">
                            <p>Scones... The essential component of English Tea Time, has already gone beyond the
                                borders of Great Britain. It became pretty popular in many countries all over the world
                                in the last decade.</p>
                            <p className="mt-6">I, as a fan of this fine baked good, couldn't bring myself to see it as
                                a side product in bakeries and coffee shops; so I chose it as the major product for my
                                new coffee shop project SPRO.</p>
                        </div>
                        <div className="w-full lg:w-1/2 mt-6 lg:mt-0 lg:pl-6">
                            <p>At SPRO we bake multiple times everyday. This can range from plain English scones, to
                                sweet scones with fruit and nuts, or savoury with vegetables and herbs. We also have
                                vegan and gluten-free forms.</p>
                            <p className="mt-6">We can serve these with several home made spreads such as, lemon curd
                                and of course original English clotted cream. It is an absolute must, to come by and
                                have an indulgent afternoon tea with us.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-spro-second py-12 lg:pb-24 font-trebuchet-ms">
                <div className="container w-11/12 xl:w-[1200] mx-auto">
                    <div className="block pb-10 lg:pb-16 text-6xl lg:text-8xl text-white flex justify-center items-center">
                        <span className="italic">e</span>
                        <span className="uppercase">spr</span>
                        <span className="italic">ess</span>
                        <span className="uppercase">o</span>
                    </div>
                    <div className="flex flex-col lg:flex-row justify-center text-white text-xl lg:text-3xl">
                        <div className="w-full lg:w-1/2 lg:pr-6">
                            <p>"SPRO" is a slang term for espresso. This shows how serious we take our coffee business.
                                We use direct trade, specialty coffee beans from plantation "Las Chicharras" in
                                Mexico. </p>
                            <p className="mt-6">We are offering this as light roasted single origin or as a darker roast
                                SPRO blend, with 20 % Indian Harley Estate Robusta.</p>
                        </div>
                        <div className="w-full lg:w-1/2 mt-6 lg:mt-0 lg:pl-6">
                            <p>The good thing is, it doesn't matter which one you choose. As with each espresso drink
                                you purchase, you directly support a school project for the children of our farmers
                                around the farm.</p>
                            <p className="mt-6">You can find more info here about our coffee and this charity
                                project.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full lg:h-[700px] overflow-hidden bg-white flex flex-col lg:flex-row justify-around">
                <div className="w-full xl:w-1/2 flex">
                    <a href="https://www.instagram.com/spro_coffee/" target="_blank" rel="nofollow"
                       className="w-1/2 h-full overflow-hidden hover:opacity-75">
                        <img src="/img/inst-1.jpg" alt="" className="w-full h-full object-cover"/>
                    </a>
                    <div className="w-1/2 flex flex-col">
                        <a href="https://www.instagram.com/spro_coffee/" target="_blank" rel="nofollow"
                           className="w-full h-1/2 overflow-hidden hover:opacity-75">
                            <img src="/img/inst-2.jpg" alt="" className="w-full h-full object-cover"/>
                        </a>
                        <a href="https://www.instagram.com/spro_coffee/" target="_blank" rel="nofollow"
                           className="w-full h-1/2 overflow-hidden hover:opacity-75">
                            <img src="/img/inst-3.jpg" alt="" className="w-full h-full object-cover"/></a>
                    </div>
                </div>
                <div className="w-full xl:w-1/2 flex flex-col">
                    <a href="https://www.instagram.com/spro_coffee/" target="_blank" rel="nofollow"
                       className="w-full h-1/2 overflow-hidden hover:opacity-75">
                        <img src="/img/inst-4.jpg" alt="" className="w-full h-full object-cover"/></a>
                    <div className="w-full h-1/2 overflow-hidden flex">
                        <a href="https://www.instagram.com/spro_coffee/" target="_blank"
                           rel="nofollow" className="w-1/2 h-full overflow-hidden hover:opacity-75">
                            <img src="/img/inst-5.jpg" alt="" className="w-full h-full object-cover"/></a>
                        <a href="https://www.instagram.com/spro_coffee/" target="_blank"
                           rel="nofollow" className="w-1/2 h-full overflow-hidden hover:opacity-75">
                            <img src="/img/inst-6.jpg" alt="" className="w-full h-full object-cover"/></a>
                    </div>
                </div>
            </div>
        </>
    )
}