import React from "react";
import {Link} from "react-router-dom";

export function Footer() {
    return (
        <footer className="w-full bg-spro-main py-16 font-trebuchet-ms text-white text-2xl">
            <div className="container w-11/12 xl:w-[1200] mx-auto">
                <div className="flex flex-col lg:flex-row">
                    <div className="lg:w-1/3 order-2 lg:order-none text-center lg:text-left">
                        <div className="block mb-6">Follow us on &nbsp;<a href="https://www.instagram.com/spro_coffee/"
                                                                          rel="nofollow"
                                                                          className="italic underline underline-offset-4 decoration-dotted hover:no-underline"
                                                                          target="_blank">instagram
                            @spro_coffee</a>&nbsp; to check out the latest news
                        </div>
                        <a className="py-3 px-5 bg-spro-second text-white rounded rounded-2xl hidden lg:inline-flex justify-center items-center hover:opacity-75"
                           href="https://www.dropbox.com/s/1qbbcz4cnq1k1wo/menuekarte_2020.pdf" target="_blank"
                           rel="nofollow">
                            <img src="/img/download.svg" alt="" className="w-[20px] mr-3"/>Download our menu</a>
                    </div>
                    <div className="w-full lg:w-1/3 order-1 lg:order-none mb-12 lg:mb-0 text-center">
                        <a href="/" className="flex justify-center"><img src="/img/logo.png" alt="" className="h-[70px] lg:h-[110px]"/></a>
                    </div>
                    <div className="w-full lg:w-1/3 order-3 lg:order-none text-center lg:text-right">
                        <div className="mb-6 flex justify-center lg:justify-end">
                            <div className="flex flex-col text-right pr-2">
                                <span>Mon – Sat:</span>
                                <span>Sunday:</span>
                            </div>
                            <div className="flex flex-col">
                                <span>9.00 – 17.00</span>
                                <span>10.00 – 17.00</span>
                            </div>
                        </div>
                        <div className="text-lg">
                            Due to the current situation our time openning Maybe varied, please always check our
                            schedule on social media
                        </div>
                        <a className="py-3 px-5 mt-8 bg-spro-second text-white rounded rounded-2xl inline-flex lg:hidden justify-center items-center hover:opacity-75"
                           href="https://www.dropbox.com/s/1qbbcz4cnq1k1wo/menuekarte_2020.pdf" target="_blank"
                           rel="nofollow">
                            <img src="/img/download.svg" alt="" className="w-[20px] mr-3"/>Download our menu</a>
                    </div>
                </div>
                <div className="mt-12 pt-12 flex flex-col lg:flex-row border border-0 border-t-2 border-t-white border-dotted">
                    <div className="w-full lg:w-1/3 order-3 lg:order-none text-center lg:text-left text-[1.25rem] mt-8 lg:mt-0">
                        © 2018–2023 SPRO. All rights reserved<br/>
                        <Link to="/impressum" onClick={() => {
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                        }} className="underline underline-offset-4 decoration-dotted hover:no-underline">Impressum</Link>
                    </div>
                    <div className="w-full lg:w-1/3 order-1 lg:order-none flex justify-center">
                        Schönhauser Allee 50A, <br/>10437 Berlin, Germany
                    </div>
                    <div className="w-full lg:w-1/3 order-2 lg:order-none flex flex-col text-center lg:text-right mt-8 lg:mt-0">
                        <a href="tel:+49 30 44328602" className="underline underline-offset-4 decoration-dotted hover:no-underline">+49 30 44328602</a>
                        <a href="mailto:hello@spro-coffee.com" className="underline underline-offset-4 decoration-dotted hover:no-underline">hello@spro-coffee.com</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}