import React from 'react';
import {Route, Routes} from "react-router-dom";
import {HomePage} from "./pages/HomePage";
import {ImprintPage} from "./pages/ImprintPage";
import {Header} from "./conponents/Header";
import {Footer} from "./conponents/Footer";

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/impressum" element={<ImprintPage/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;
