import React from "react";

export function ImprintPage() {
    return (
        <>
            <div className="w-full bg-spro-grey py-12 font-trebuchet-ms text-lg">
                <div className="container w-11/12 lg:w-[800px] mx-auto">
                    <div className="block pb-16 text-4xl lg:text-6xl text-spro-second text-center">Impressum</div>
                    <div className="flex flex-col lg:flex-row justify-center text-2xl">
                        <div className="w-full lg:w-1/2 text-justify lg:pr-6">
                            <p>Angaben gemäß § 5 TMG</p>
                            <p className="mt-6">Jonathan Ilker Oekten<br/>
                                Schoenhauser Allee 50a<br/>
                                10437 Berlin</p>
                        </div>
                        <div className="w-full lg:w-1/2 text-justify mt-6 lg:mt-0 lg:pl-6">
                            <p>Kontakt</p>
                            <p className="mt-6">Telefon: +49 (30) 443 28 602<br/>
                                Telefax: +49 (30) 443 28 602<br/>
                                E-Mail: <a href="mailto:hello@spro-coffee.com"
                                           className="underline underline-offset-4 decoration-dotted hover:no-underline">hello@spro-coffee.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white py-12 font-trebuchet-ms text-lg">
                <div className="container w-11/12 lg:w-[800px] mx-auto">
                    <p>Haftung für Inhalte</p>
                    <p className="mt-6">Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder
                        nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                    <p className="mt-6">Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    <p className="mt-6">Haftung für Links</p>
                    <p className="mt-6">Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
                        haben.Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                        Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
                        erkennbar.</p>
                    <p className="mt-6">Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                        werden wir derartige Links umgehend entfernen.</p>
                    <p className="mt-6">Urheberrecht</p>
                    <p className="mt-6">Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
                        nicht kommerziellen Gebrauch gestattet.</p>
                    <p className="mt-6">Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                        trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                        Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.</p>
                </div>
            </div>
        </>
    )
}