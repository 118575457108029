import React, {useEffect, useState} from "react";

export function Header() {
    const [sticky, setSticky] = useState('h-[8rem]')
    const [logoSize, setLogoSize] = useState('h-[80px]')
    const [instLink, setInstLink] = useState('opacity-0')

    useEffect(() => {
        window.addEventListener('scroll', isSticky)
        return () => {
            window.removeEventListener('scroll', isSticky)
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY
        const stickyClass = scrollTop > 0 ? 'h-[4rem]' : 'h-[8rem]'
        const logoClass = scrollTop > 0 ? 'h-[40px]' : 'h-[80px]'
        const instLinkClass = scrollTop > 0 ? 'opacity-75' : 'opacity-0'
        setLogoSize(logoClass)
        setSticky(stickyClass)
        setInstLink(instLinkClass)
    };

    const headerClasses = `sticky top-0 ease-out duration-300 z-10 items-center w-full bg-spro-main ${sticky}`
    const logoClasses = `flex ease-out duration-300 ${logoSize}`
    const instLinkClasses = `flex ease-out duration-300 italic text-white font-extralight text- md:text-xl underline underline-offset-4 
                            decoration-dotted hover:no-underline hover:opacity-100 absolute right-0 ${instLink}`

    return(
        <header id="header" className={headerClasses}>
            <div className="container w-11/12 xl:w-[1200] mx-auto h-full flex justify-center items-center relative">
                <a href="/" className="shrink-0"><img src="/img/logo-mini.png" alt="" className={logoClasses}/></a>
                <a href="https://www.instagram.com/spro_coffee/" rel="nofollow noopener" target="_blank" className={instLinkClasses}>@spro_coffee</a>
            </div>
        </header>
    )
}